export type ApiResponseError = {
  error: EnumErrorCode;
  message: string | EnumErrorCode[];
  statusCode: number;
};

export enum EnumErrorCode {
  // Balance

  // Bill
  BILL_SEARCH_ALREADY_PAID = "payment.error.bill.search.already.paid",
  BILL_SEARCH_INVALID_BARCODE = "payment.error.bill.search.invalid.barcode",
  BILL_SEARCH_EXPIRED = "payment.error.bill.search.expired",
  BILL_SEARCH_UNSUPPORTED_INSTITUTION = "payment.error.bill.search.unsupported.institution",
  BILL_SEARCH_WITH_DIVERGENCE = "payment.error.bill.search.with.divergence",
  BILL_SEARCH_UNSUPPORTED_ASSIGNOR = "payment.error.bill.search.unsupported.assignor",
  BILL_SEARCH_INVALID_BY_GLOBAL_RULE = "payment.error.bill.search.invalid.global.rule",
  BILL_SEARCH_INVALID_BY_ESTABLISHMENT_RULE = "payment.error.bill.search.invalid.establishment.rule",
  BILL_SEARCH_UNKNOWN_ERROR = "payment.error.bill.search.unknown.error",

  //PIX
  PIX_INVALID_EMV = "payment.error.pix.invalid.emv",
  PIX_INVALID_KEY = "payment.error.pix.invalid.key",
  PIX_SEARCH_UNKNOWN_ERROR = "payment.error.pix.search.unknown.error",
  PIX_SEARCH_UNSUPPORTED_INSTITUTION = "payment.error.pix.search.unsupported.institution",
  PIX_SEARCH_UNSUPPORTED_ASSIGNOR = "payment.error.pix.search.unsupported.assignor",
  PIX_SEARCH_INVALID_BY_ESTABLISHMENT_RULE = "payment.error.pix.search.invalid.establishment.rule",
  PIX_SEARCH_INVALID_BY_GLOBAL_RULE = "payment.error.pix.search.invalid.global.rule",
  PIX_DUEDATE_EXPIRED = "payment.error.pix.search.duedate.expired",
  PIX_VALUE_INVALID = "payment.error.pix.search.value.invalid",

  // Checkout
  CHECKOUT_CHARGE_OPTIONS_EXPIRED = "checkout.error.charge-options.expired",
  CHECKCOUT_CHARGE_NOT_SUCCEEDED = "checkout.error.charge.not.succeeded",
  CHECKOUT_RISK_ANALYSIS_REJECTED = "checkout.error.risk.analysis.rejected",
  CHECKOUT_THREE_DS_CHALLENGE_NOT_FINISHED = "checkout.error.three.ds.challenge.not.finished",

  // Charge
  CHARGE_CHARGE_OPTIONS_EXPIRED = "charge.error.charge-options.expired",
  CHARGE_THREE_DS_CHALLENGE_NOT_FINISHED = "charge.error.three.ds.challenge.not.finished",
  CHARGE_CREATE_ORDER_3DS_ERROR = "charge.error.create.order.3ds.error",
  CHARGE_CREATE_ORDER_CHALLENGE_3DS_ERROR = "charge.error.create.order.challenge.3ds.error",

  NOT_FOUND_RULE = "payment.error.search.not.found.rule",
}

const pixErrorMessages = {
  [EnumErrorCode.PIX_DUEDATE_EXPIRED]: "Código PIX expirado!",
  [EnumErrorCode.PIX_INVALID_EMV]: "Pix copia e cola inválido!",
  [EnumErrorCode.PIX_INVALID_KEY]: "Chave PIX inválida!",
  [EnumErrorCode.PIX_SEARCH_UNKNOWN_ERROR]:
    "Erro desconhecido, tente novamente!",
  [EnumErrorCode.PIX_SEARCH_UNSUPPORTED_INSTITUTION]:
    "Instituição não autorizada em nosso sistema!",
  [EnumErrorCode.PIX_SEARCH_UNSUPPORTED_ASSIGNOR]:
    "Cedente não autorizado em nosso sistema!",
  [EnumErrorCode.PIX_SEARCH_INVALID_BY_ESTABLISHMENT_RULE]:
    "Estabelecimento não autorizado em nosso sistema!",
  [EnumErrorCode.PIX_SEARCH_INVALID_BY_GLOBAL_RULE]:
    "Código PIX bloqueado para consulta!",
  [EnumErrorCode.PIX_VALUE_INVALID]: "Valor PIX inválido, tente novamente!",
};

const billErrorMessages = {
  [EnumErrorCode.BILL_SEARCH_ALREADY_PAID]: "Boleto já foi pago!",
  [EnumErrorCode.BILL_SEARCH_INVALID_BARCODE]: "Código de barra inválido!",
  [EnumErrorCode.BILL_SEARCH_EXPIRED]: "Boleto expirado!",
  [EnumErrorCode.BILL_SEARCH_UNSUPPORTED_INSTITUTION]:
    "Não trabalhamos com essa instituição em nosso sistema!",
  [EnumErrorCode.BILL_SEARCH_WITH_DIVERGENCE]:
    "Busca com divergência, tente novamente!",
  [EnumErrorCode.BILL_SEARCH_UNSUPPORTED_ASSIGNOR]:
    "Cedente não autorizado em nosso sistema!",
  [EnumErrorCode.BILL_SEARCH_INVALID_BY_GLOBAL_RULE]:
    "Boleto bloqueado para consulta!",
  [EnumErrorCode.BILL_SEARCH_INVALID_BY_ESTABLISHMENT_RULE]:
    "Estabelecimento não autorizado em nosso sistema!",
  [EnumErrorCode.BILL_SEARCH_UNKNOWN_ERROR]:
    "Erro desconhecido, tente novamente!",
};

const checkoutErrorMessages = {
  [EnumErrorCode.CHECKCOUT_CHARGE_NOT_SUCCEEDED]:
    "Transação negada, tente novamente com outro cartão!",
  [EnumErrorCode.CHECKOUT_RISK_ANALYSIS_REJECTED]:
    "Transação não autorizada, tente novamente com outro cartão!",
};

const chargeErrorMessages = {
  [EnumErrorCode.CHARGE_CREATE_ORDER_3DS_ERROR]: "Erro ao criar pedido com 3DS",
  [EnumErrorCode.CHARGE_CREATE_ORDER_CHALLENGE_3DS_ERROR]:
    "Erro na resolução do desafio",
};

const debitsErrorMessages = {
  "License plate is required": "A Placa do veículo é obrigatória.",
};

const errorMessages: Partial<Record<EnumErrorCode, string>> & {
  [key: string]: string;
} = {
  ...pixErrorMessages,
  ...billErrorMessages,
  ...chargeErrorMessages,
  ...checkoutErrorMessages,
  ...debitsErrorMessages,
  [EnumErrorCode.NOT_FOUND_RULE]: "Erro desconhecido, tente novamente!",
};

export function getApiErrorMessages({ error, message }: ApiResponseError) {
  if (error === EnumErrorCode.CHECKOUT_CHARGE_OPTIONS_EXPIRED) return;
  if (error === EnumErrorCode.CHARGE_CHARGE_OPTIONS_EXPIRED) return;
  if (error === EnumErrorCode.CHARGE_THREE_DS_CHALLENGE_NOT_FINISHED) return;
  if (error === EnumErrorCode.CHECKOUT_THREE_DS_CHALLENGE_NOT_FINISHED) return;

  if (Array.isArray(message)) {
    const firstItem = message[0] as EnumErrorCode;

    if (firstItem in errorMessages) {
      return errorMessages[firstItem];
    }
  }

  return (
    errorMessages[error] ||
    "Houve um erro inesperado, tente novamente mais tarde!"
  );
}
